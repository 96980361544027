import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import "./Home.css";

export function DisplaySavedMessage() {
    const [showMessage, setShowMessage] = useState(true);
    const toggleShowMessage = () => setShowMessage(!showMessage);

    return (
        <Row>
            <Col className="mb-2" >
                {/*<Button onClick={toggleShowMessage} className="mb-2">*/}
                {/*    Toggle Toast <strong>with</strong> Animation*/}
                {/*</Button>*/}
                <Toast show={showMessage} onClose={toggleShowMessage} delay={5000} autohide>
                    <Toast.Header>
                        <h6><b>Data Saved Successfully</b></h6>
                    </Toast.Header>

                </Toast>
            </Col>
        </Row>
    );
}