import React from 'react';

export function Footer() {
    return (
        <div className='text-center p-3' style={{ backgroundColor: "black", color: "white", marginTop: "auto" }}>
            All rights reserved <a href='https://reinz.co.nz/' style={{ color: "white" }}>
                REINZ
            </a> &copy; {new Date().getFullYear()} Copyright
        </div>
        //<footer style={{ backgroundColor: "black", color: "white", textAlign: "center" }}>
        //    <p>All rights reserved REINZ. Copyright � 2024</p>
            
        //</footer>
    );

}