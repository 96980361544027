import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Placeholder from 'react-bootstrap/Placeholder';
import { GetAccessToken } from "../index";
import { useMsal } from "@azure/msal-react";
import { DisplaySavedMessage } from './DisplaySavedMessage';

export const AddNilReturn = (props) => {
    const { instance } = useMsal();
    const [data, setData] = useState(false);
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [nil, setnil] = useState({
        "ReinzId": "",
        "ReturnPeriod": (new Date((new Date()).setMonth((new Date()).getMonth()))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth()))).getFullYear(), 
        "IsNilReturn": false,
        "Residential": false,
        "Rural":false
       
    });
    function handle(e) {
        const newnil = { ...nil }
        newnil[e.target.id] = e.target.value
        setnil(newnil)
        console.log(newnil)
    }
    function checkboxHandle(e) {
        const { name, value, checked, type } = e.target;
        setnil
            (
                prevData =>
                ({
                    ...prevData,
                    [name]: type ===
                        'checkbox' ? checked : value,
                    IsNilReturn: true
                }));
    }
    const [showLastMonths] = useState([
        {
            key: 1,
            value: (new Date((new Date()).setMonth((new Date()).getMonth()))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth()))).getFullYear()
        },
        {
            key: 2,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 1))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 1))).getFullYear()
        },
        {
            key: 3,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 2))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 2))).getFullYear()
        },
        {
            key: 4,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 3))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 3))).getFullYear()
        }
    ]);

    
    const Submit = async (e) => {
        e.preventDefault();
        /*alert("Hello1");*/
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        const url = "/api/NilReturn/PostNilReturn";
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        try {
            const accessToken = await GetAccessToken(tokenRequest);
            //fetch
            fetch(url,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(nil),

                })
                .then((response) => {
                    console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                }).then(data => {
                    setData(data)
                    //console.log(data)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }
    }


    return (
        <>
            <Placeholder xs={12} style={{ backgroundColor: "#331b8c" }} />
            <div><br />
                <Alert style={{ backgroundColor: "lightgrey", fontColor:"#331b8c" }}>
                    <Alert.Heading><b>File a Nil Return</b></Alert.Heading>
                    <Form noValidate validated={validated}><br />
                    {data === true && <DisplaySavedMessage />}
                    <Form.Group className="mb-3">
                    <Form.Label><b>Return Period</b></Form.Label>
                    <Form.Select type="select" id="ReturnPeriod" value={nil.ReturnPeriod} onChange={(e) => handle(e)} required>
                    {showLastMonths.map((info) => (
                    <option key={info.key}>{info.value}</option>
                    ))}
                    </Form.Select>
                    </Form.Group>
                    <Form.Group>
                    <Form.Label><b>Office:</b></Form.Label>
                    <InputGroup>
                    <InputGroup.Text style={{ background: "transparent" }}>
                    <i className="bi bi-search iconStyles"></i>
                    </InputGroup.Text>
                    <Form.Control type="text" placeholder="Start typing to search through Agencies" value={nil.ReinzId} id="ReinzId" onChange={(e) => handle(e)} required></Form.Control>
                    </InputGroup>
                    </Form.Group><br/>

                    <Form.Group as={Row}>
                    <Col sm={1}>
                    <Form.Check type="checkbox" checked={nil.Residential} name="Residential" onChange={(e) => checkboxHandle(e)} />
                    </Col>
                    <Form.Label column sm={11}><b>Residential</b></Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                    <Col sm={1}>
                    <Form.Check type="checkbox" checked={nil.Rural} name="Rural" onChange={(e) => checkboxHandle(e)} />
                    </Col>
                    <Form.Label column sm={11}><b>Rural</b></Form.Label>
                    </Form.Group><br />
                    <Button style={{ backgroundColor: "#331b8c" }} type="submit" onClick={Submit}>
                    File Return 
                    </Button>&nbsp;&nbsp;
                    {/*<Button variant="light" type="cancel">*/}
                    {/*Cancel*/}
                    {/*</Button><br />     */}
                </Form>
                </Alert>
          
            </div>
        </>
    );
}
