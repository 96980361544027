import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { msalConfig } from '../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
/*import { Button } from "bootstrap"*/
import Button from 'react-bootstrap/Button';

export const SignOut = () => {
    const { instance } = useMsal();
    /*const myMSALObj = new PublicClientApplication(msalConfig);*/

    const handleLogin = (e) => {
        instance.logoutRedirect();
       /* console.log("Hello world")*/
        console.log(e);
    };

    return (
        <Button variant="light" onClick={handleLogin}>Sign Out</Button>
    );
}