import React from 'react';
import { Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../img/reinz-logo.png';
import { useIsAuthenticated} from "@azure/msal-react";
import { SignIn } from './SignIn';
import { SignOut } from './SignOut';

export function NavMenu() {
    const isAuthenticated = useIsAuthenticated();
    return(
      <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 bg-black" container >
            <NavbarBrand tag={Link} to="https://reinz.co.nz"><img
            src={logo}
            alt="logo" /></NavbarBrand>
            {/* <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />*/}
            {/*<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>*/}
                    <ul className="navbar-nav flex-grow">
                    {isAuthenticated?
                      <NavItem>
                      <NavLink tag={Link} className="text-white" to="/"><SignOut /></NavLink>
                      </NavItem>:
                      <NavItem>
                      <NavLink tag={Link} className="text-white" to="/"><SignIn /></NavLink>
                      </NavItem>
                    }
                    </ul>
            {/* </Collapse>*/}
            </Navbar>
        </header>
    );
}
