import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import 'bootstrap-icons/font/bootstrap-icons.css';
import InputGroup from 'react-bootstrap/InputGroup'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Placeholder from 'react-bootstrap/Placeholder';
import { useState } from 'react';
import { GetAccessToken } from "../index";
import { useMsal } from "@azure/msal-react";
import { DisplaySavedMessage } from './DisplaySavedMessage'
import { AddResidentialReturn } from './AddResidentialReturn';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

export function AddRuralReturn() {
    const { instance } = useMsal();
    const [message, setMessage] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showResidential, setshowResidential] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [searchAddressResults, setAddressSearchResults] = useState([]);
    const [searchSuburbResults, setSuburbSearchResults] = useState([]);
    const [searchOrgResults, setOrgSearchResults] = useState([]);
    const [searchOrgsResults, setOrgsSearchResults] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedSuburb, setSelectedSuburb] = useState(null);
    const [isAddressVisible, setAddressIsVisible] = useState(true);
    const [isSuburbVisible, setSuburbIsVisible] = useState(true);
    const [isOrgVisible, setOrgIsVisible] = useState(true);
    const [isOrgsVisible, setOrgsIsVisible] = useState(false);
    const handleClose = () => setShowModal(false);
    const [rural, setrural] = useState({
        /*Primary property info*/
        OfficeReinzId:"",
        ReturnType: "Rural",
        SaleIsConjoint: false,
        ConjointOfficeReinzId:null,
        ConjointOfficeReinzIds: null,
        AdminNotes: "",
        ReturnPeriod: (new Date((new Date()).setMonth((new Date()).getMonth()))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth()))).getFullYear(),
        ExternalId:"",
        AddressSearch:null,
        ValRef:"",
        Unit:"",
        Suffix:"",
        StreetNumber:"",
        Street:"",
        Suburb:null,
        PostalCode:0,
        /*Title:"",*/
        CertificateOfTitle:"",
        FreeholdStatus:"",
        Category: "",
        //fields not present on rural form
        Comments:"",
        DiseaseStatus:"",
        LifestyleCategory:"",
        LivestockCategory:"",
        Outlook:"",
        RuralCouncil:"",


        /*Rural info*/
        BareLand: false,
        Farmlet: false,
        Contour: null,
        IrrigationType: null, 
        WaterSupply: null,
        LandArea:0,
        Bedrooms: null,
        Bathrooms:null,
        Dwellings:0,
        MainDwelling: null,
        AdditionalDwelling: null,
        Improvements: null,
        AdditionalComments: "",
        Suppressed: false,
        GST:false,
        /*Horticulture fields*/
        HorticultureCrop:"",
        /*Dairy fields*/
        DairyCompany:"",
        MilkSolids:0,
        CowsMilked:0,
        /*Arable fields*/
        ArableMaize:0,
        ArableOats:0,
        ArableBarley:0,
        ArableBrassica:0,
        ArableLucerne:0,
        ArableBroadleaf: 0,
        ArableHay:0,
        ArableAnimalPasture:0,
        ArablePermanentPasture: 0,
        ArableKiwifruit:0,
        ArablePipfruit:0,
        ArableVegetables:0,
        ArableViticulture:0,
        ArableOrchard:0,
        ArableOtherGrain:0,
        ArableChicory:0,
        ArablePlantain:0,
        ArableRyegrass:0,
        ArableClover:0,
        ArableOtherSeeds:0,
        ArableFodderCrops:0,
        ArableMixed:0,
        ArableOther:0,
        /*Forestry fields*/
        /*ForestryCertificate:0,*/
        ForestryRadiata:0,
        ForestryDouglasFir:0,
        ForestryEucalyptus:0,
        ForestryAcacia:0,
        ForestryNative:0,
        CarbonCredits: false,
        ContractManagement: false,
        /*Livestock fields*/
        LivestockBeefHeifers:0,
        LivestockDiaryHeifers1Year:0,
        LivestockDiaryHeifers2Year:0,
        LivestockDairyCows:0,
        LivestockUnits:0,
        /*Additional information*/
        SaleMethod: null,
        ListPrice: null,
        SalePrice: null,
        ListDate: null,
        AgreementDate: null,
        UnconditionalDate: null,
        SettlementDate: null,
        Valuation: null,
        ValuationDate: null,
        LandValue: null,
        ValuationImprovement: null,
        //fields used by API's but not by forms
        Latitude: 0,
        Longitude: 0,
        LinzTlaId: 0,
        TlaId: 0,
        TownCity: null,
        LandClass: null,
    });
    const newrural = { ...rural }
    var addressEntered = "";
    var suburbEntered = "";
    var orgEntered = "";
    const isClicked = () => {
        if (showResidential === true) {
            setshowResidential(false);
        } else {
            setshowResidential(true);
        }
        handleClose();
    }
    function handle(e) {
        const newrural = { ...rural }
        newrural[e.target.id] = e.target.value
        setrural(newrural)
        console.log(newrural)
    }

    function checkboxHandle(e) {
        const { name, value, checked, type } = e.target;
        setrural
            ( prevData =>
                ({
                    ...prevData,
                    [name]: type ===
                        'checkbox' ? checked : value
                }));
    }
    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const [showLastMonths] = useState([
        {
            key: 1,
            value: (new Date((new Date()).setMonth((new Date()).getMonth()))).getMonth() + 1+'/' + (new Date((new Date()).setMonth((new Date()).getMonth()))).getFullYear()
        },
        {
            key: 2,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 1))).getMonth() +1+ '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 1))).getFullYear()
        },
        {
            key: 3,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 2))).getMonth() +1+ '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 2))).getFullYear()
        },
        {
            key: 4,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 3))).getMonth() +1+ '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 3))).getFullYear()
        }
    ]);
   
    useEffect(() => {
        const fetchAgency = async () => {
            const url = "api/ReturnList/GetAgency";
            const tokenRequest = {
                scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
                account: instance.getActiveAccount()
            };
            const accessToken = await GetAccessToken(tokenRequest);

            try {
                await fetch(url,
                    {
                        method: "GET",
                        headers: new Headers(
                            {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${accessToken}`
                            }),
                    })
                    .then((response) => {
                        console.log(response, 'response');
                        if (response.status !== 200 && response.status !== 201) {
                            console.log("not ok" + response.status);
                        }
                        else {
                            return response.json()
                        }
                    })
                    .then(data => {
                        console.log(data)
                        if (data !== null && data !== undefined) {
                            newrural.OfficeReinzId = data;
                            setrural(newrural);
                        }
                        console.log(newrural);
                    })
                    //setIsLoading(false)
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
            catch (error) {
                if (error.response) {
                    console.log(error);
                }
            }
        }
        fetchAgency();
    }, [])
    function handleAddressSearch(e) {
        const { id } = e.target;
        addressEntered = e.target.value;
        setSelectedAddress(addressEntered);
        if (addressEntered.length >= 6) {
            handleAddress();
        }
    }
    function handleSuburbSearch(e) {
        const { id } = e.target;
        suburbEntered = e.target.value;
        setSelectedSuburb(suburbEntered);
        if (suburbEntered.length >= 4) {
            handleSuburb();
        }
    }
    function handleConjointOfficesSearch(e) {
        const { id } = e.target;
        orgEntered = e.target.value;
        /*setSelectedOrg(orgEntered);*/
        if (orgEntered.length >= 4) {
            handleConjointOffices();
        }
    }
    const handleAddress = async () => {
        const url = "api/address/GetAddress";
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        const accessToken = await GetAccessToken(tokenRequest);
        try {
            fetch(url, {
                method: "POST",
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }),
                body: JSON.stringify(addressEntered)
            })
                .then((response) => {
                    console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                }).then(data => {
                    console.log(data)
                    if (data !== null && data !== undefined) {
                        setAddressSearchResults(data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching addresses:', error);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }

    }
    const handleSuburb = async () => {
        const url = "api/address/GetSuburb";
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        const accessToken = await GetAccessToken(tokenRequest);
        try {
            fetch(url, {
                method: "POST",
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }),
                body: JSON.stringify(suburbEntered)
            })
                .then((response) => {
                    console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                }).then(data => {
                    console.log(data)
                    if (data !== null && data !== undefined) {
                        setSuburbSearchResults(data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching suburbs', error);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }

    }
    const handleConjointOffices = async () => {
        const url = "api/organisation/GetOrganisation";
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        const accessToken = await GetAccessToken(tokenRequest);
        try {
            fetch(url, {
                method: "POST",
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }),
                body: JSON.stringify(orgEntered)
            })
                .then((response) => {
                    console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                }).then(data => {
                    console.log(data)
                    if (data !== null && data !== undefined) {
                        setOrgSearchResults(data);
                        setOrgIsVisible(true);
                    }
                })
                .catch(error => {
                    console.error('Error fetching organisations', error);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }
    }

    const Submit = async (e) => {
        /*e.preventDefault();*/
        /*alert("Hello1");*/
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        scrollTop();
        const url = "api/RuralReturn/PostRuralReturn";
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        try {
            const accessToken = await GetAccessToken(tokenRequest);
            //fetch
            fetch(url,
                {
                    method: 'POST',
                    //mode: "no-cors",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(rural),

                })
                .then((response) => {
                    console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                }).then(data => {
                    setMessage(data);
                    setTimeout(() => {
                        setMessage(false);
                    }, 5000);
                    //console.log(data)
                    //window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }
        
    }
    const handleSelectAddress = (address) => {
        setAddressIsVisible(false);
        newrural.ValRef = address.valRef;
        newrural.AddressSearch = address.addressSearch;
        newrural.Unit = address.unit;
        newrural.Suffix = address.suffix;
        newrural.StreetNumber = address.streetNumber.toString();
        newrural.Street = address.street;
        newrural.Suburb = address.suburbName.toString();
        newrural.TownCity = address.townCity;
        newrural.PostalCode = address.postcode;
        newrural.LandValue = address.landValue;
        newrural.Longitude = address.longitude;
        newrural.Latitude = address.latitude;
        newrural.Valuation = address.councilValuation;
        newrural.Bathrooms = address.bathrooms;
        newrural.Bedrooms = address.bedrooms;
        newrural.FloorArea = address.floorArea;
        newrural.LandArea = address.landArea;
        newrural.ValuationImprovement = address.improvementValue;
        newrural.CertificateOfTitle = address.certificateOfTitle;
        setrural(newrural);
    }
    const handleSelectSuburb = (suburb) => {
        /*setSelectedAddress(address);*/
        setSuburbIsVisible(false);
        newrural.Suburb = suburb;
        setrural(newrural);
    };
    const handleSelectOrg = (org) => {
        setOrgIsVisible(!isOrgVisible);

        if (org && !searchOrgsResults.includes(org.trim())) {
            setOrgsSearchResults((searchOrgsResults) => {
                const updatedOrgsList = [...searchOrgsResults, org.trim()]
                // Update state
                setrural((prevData) => ({ ...prevData, ConjointOfficeReinzId: org }));
                /*setOrgSearchResults(['']);*/
                return updatedOrgsList;// Return the updated list for the state
            });
        }
        setOrgsIsVisible(true);
    };

    const handleRemoveSearchTerm = (org) => {
        setOrgsSearchResults((searchOrgsResults) => {
            const updatedOrgsList = searchOrgsResults.filter((searchorg) => searchorg !== org)
            const updatedResidential = {
                ...newrural,
                ConjointOfficeReinzId: updatedOrgsList
            };
            // Update state
            setrural(updatedResidential);
            return updatedOrgsList;// Return the updated list for the state
        });
    }

    const renderTooltip_returntype = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Changing this to a Residential Return will remove all rural specific fields
        </Tooltip>
    );

    const renderTooltip_saleisconjoint = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Marking a sale as conjoint will allow REINZ to match this sale with returns submitted by participating offices
        </Tooltip>
    );

    const renderTooltip_returnperiod = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Return Period will be determined by the date entered in the Unconditional Date field below
        </Tooltip>
    );

    const renderTooltip_externalid = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            If your agency requires your return to contain an ID for reporting purposes, enter it here
        </Tooltip>
    );

    const renderTooltip_supresssale = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Supressing a sale prevents it being searchable until the sale has settled or 6 months.
            Supressing sales is actively monitored by REINZ and you will be asked the reason why it has supressed.
            Supressing should only be done for legal privacy reasons.
        </Tooltip>
    );

    const renderTooltip_unconditionaldate = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Unconditional Date will be used to determine which Return Period this return will be submitted under.
        </Tooltip>
    );
    return (
        <>
            <Modal size="lg" show={showModal} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to change this return from rural to residential return?</Modal.Title>
                </Modal.Header>
                <Modal.Body>This will remove all rural data from the return form</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                Cancel
                </Button>
                <Button style={{ backgroundColor: "#331b8c" }} onClick={isClicked}>
                Confirm
                </Button>
                </Modal.Footer>
            </Modal>
            {showResidential ? <AddResidentialReturn /> :
                <>
                    <Placeholder xs={12} style={{ backgroundColor: "#331b8c" }} />
                    <Form noValidate validated={validated}><br />
                        {message === true && <DisplaySavedMessage />}
                        {/*Primary Property Information*/}
                        <Form.Label><h4>Primary Property Information</h4></Form.Label><br />
                        <Form.Group as={Row}>
                        <Form.Label column sm={2}>Company<sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                        <Form.Label required>{rural.OfficeReinzId}</Form.Label>
                        {rural.OfficeReinzId === "" &&
                        <Form.Control as="label" className="required">
                        Company is required
                        </Form.Control>}
                        <br />
                        </Col>


                        <Form.Label column sm={2}>Return Type
                        <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip_returntype}>
                        <sup>?</sup>
                        </OverlayTrigger></Form.Label>
                        <Col sm={10}>
                        <Form.Label id="ReturnType" value={rural.ReturnType}>Rural</Form.Label>&nbsp;&nbsp;&nbsp;
                        <Button style={{ backgroundColor: "#331b8c" }} onClick={() => setShowModal(true)}><i className="bi bi-arrow-repeat iconStyles"></i>&nbsp;Change Return Type</Button>
                        </Col>

                        <Form.Label column sm={2}>Sale Is Conjoint
                        <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip_saleisconjoint}>
                        <sup>?</sup>
                        </OverlayTrigger></Form.Label>
                        <Col sm={10}>
                        <Form.Check type="checkbox" checked={rural.SaleIsConjoint} name="SaleIsConjoint" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={2}>Conjoint Companies</Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                                <InputGroup.Text style={{ background: "transparent" }}>
                                    <i className="bi bi-search iconStyles"></i>
                                </InputGroup.Text>
                                    <Form.Control type="text" placeholder="Start typing to search through Agencies" id="ConjointOfficeReinzId" value={rural.ConjointOfficeReinzId||null} onChange={(e) => handleConjointOfficesSearch(e)} />
                                </InputGroup>
                                {isOrgVisible &&
                                    <ul id="ConjointOfficeReinzId">
                                        {searchOrgResults.map((org, index) => (
                                            <li key={index} onClick={() => handleSelectOrg(org)}>
                                                {org}
                                            </li>
                                        ))}
                                    </ul>
                                }
                                {isOrgsVisible &&
                                    <ul id="ConjointOfficeReinzId">
                                        {searchOrgsResults.map((org, index) => (
                                            <li key={index}>
                                                <button style={{ backgroundColor: "#331b8c", color: "white" }} onClick={() => handleRemoveSearchTerm(org)}><i className="bi bi-trash3-fill"></i>&nbsp;Remove</button>&nbsp;&nbsp;&nbsp;
                                                <span>{org}</span>

                                            </li>
                                        ))}
                                    </ul>
                                }<br />
                        </Col>

                        <Form.Label column sm={2}>REINZ Admin Notes</Form.Label>
                        <Col sm={10}>
                            <Form.Control as="textarea" row="2" value={rural.AdminNotes} id="AdminNotes" onChange={(e) => handle(e)} /><br />
                        </Col>

                        <Form.Label column sm={2}>Return Peroid
                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip_returnperiod}>
                                <sup>?</sup>
                            </OverlayTrigger></Form.Label>
                        <Col sm={10}>
                            <Form.Select type="select" id="ReturnPeriod" value={rural.ReturnPeriod} onChange={(e) => handle(e)}>
                                {showLastMonths.map((info) => (
                                    <option key={info.key}>{info.value}</option>
                                ))}
                            </Form.Select><br />
                        </Col>

                        <Form.Label column sm={2}>External ID
                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip_externalid}>
                                <sup>?</sup>
                            </OverlayTrigger></Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" id="ExternalId" value={rural.ExternalId} onChange={(e) => handle(e)} ></Form.Control><br />
                        </Col>

                        <Form.Label column sm={2}>Address Search</Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                                <InputGroup.Text style={{ background: "transparent" }}>
                                <i className="bi bi-search iconStyles"></i>
                                </InputGroup.Text>
                                <Form.Control type="text" placeholder="Start typing to search through addresses" id="AddressSearch" name="AddressSearch" value={rural.AddressSearch} onChange={(e) => handleAddressSearch(e)}></Form.Control>
                            </InputGroup>
                                {selectedAddress === null &&
                                <p>Selecting an address with this search box will overwrite any changes you've made to this form</p>
                                }
                                {isAddressVisible &&
                                    <ul id="addressSearch">
                                        {searchAddressResults.map((address) => (
                                            <li key={address.id} onClick={() => handleSelectAddress(address)}>
                                                {address.addressSearch}
                                            </li>
                                        ))}
                                    </ul>
                                }
                            {/*Hide the dropdown on address search*/}
                            {/*{rural.AddressSearch !== null &&*/}
                            {/*<Form.Select>*/}
                            {/*<option>{rural.AddressSearch}</option>*/}
                            {/*</Form.Select> */}
                            {/*}*/}
                            <br />
                        </Col>

                        <Form.Label column sm={2}>Val Ref</Form.Label>
                        <Col sm={10}>
                        <Form.Control type="text" placeholder="Example  22900/42602" id="ValRef" value={rural.ValRef} onChange={(e) => handle(e)}></Form.Control>
                        <br/>
                        </Col>

                        <Form.Label column sm={2}>Unit Number</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" id="Unit" value={rural.Unit} onChange={(e) => handle(e)}></Form.Control><br />
                        </Col>

                        <Form.Label column sm={2}>Suffix</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" id="Suffix" value={rural.Suffix} onChange={(e) => handle(e)} ></Form.Control><br />
                        </Col>

                        <Form.Label column sm={2}>Street Number <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                        <Form.Control type="text" id="StreetNumber" value={rural.StreetNumber} onChange={(e) => handle(e)} required ></Form.Control>
                            {rural.StreetNumber === "" &&
                            <Form.Control as="label" className="required">Street Number is required</Form.Control>
                            }
                            <br />
                        </Col>

                        <Form.Label column sm={2}>Street Name <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" id="Street" value={rural.Street} onChange={(e) => handle(e)} required ></Form.Control>
                            {rural.Street === "" &&
                                <Form.Control as="label" className="required">Street Name is required</Form.Control>
                            }
                            <br />
                        </Col>

                        <Form.Label column sm={2}>Suburb <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                                <InputGroup.Text style={{ background: "transparent" }}>
                                <i className="bi bi-search iconStyles"></i>
                                </InputGroup.Text>
                                    <Form.Control type="text" id="suburbName" value={rural.Suburb} placeholder="Start typing to search through suburbs" onChange={(e) => handleSuburbSearch(e)} required minLength={4}></Form.Control>
                            </InputGroup>
                            {rural.Suburb === "" &&
                                <Form.Control as="label" className="required">Suburb is required</Form.Control>
                                }
                                {isSuburbVisible &&
                                    <ul id="suburbName">
                                        {searchSuburbResults.map((suburb) => (
                                            <li key={suburb} onClick={() => handleSelectSuburb(suburb)}>
                                                {suburb}
                                            </li>
                                        ))}
                                    </ul>
                                }
                            <br />
                        </Col>

                        <Form.Label column sm={2}>Post Code</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" id="PostalCode" value={rural.PostalCode} onChange={(e) => handle(e)}></Form.Control><br />
                        </Col>

                        <Form.Label column sm={2}>Title</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" id="CertificateOfTitle" value={rural.CertificateOfTitle} onChange={(e) => handle(e)}></Form.Control><br />
                        </Col>
                        {/*Latitude*/}
                            <div style={{display:"none"} }>
                                <Form.Label column sm={2}>Latitude</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="text" id="Latitude" value={newrural.Latitude} onChange={(e) => handle(e)}></Form.Control>
                                    <br />
                                </Col>
                            </div>
                            
                        {/*Longitude*/}
                            <div style={{ display: "none" }}>
                                <Form.Label column sm={2}>Longitude</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="text" id="Longitude" value={newrural.Longitude} onChange={(e) => handle(e)}></Form.Control>
                                    <br />
                                </Col>
                            </div>
                            
                        
                        <Form.Label column sm={2}>Freehold Status <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Select id="FreeholdStatus" value={rural.FreeholdStatus} onChange={(e) => handle(e)} required>
                                <option></option>
                                <option value="Unknown">Unknown</option>
                                <option value="Freehold">Freehold</option>
                                <option value="Leasehold">Leasehold</option>
                                <option value="Crosslease">Crosslease</option>
                                <option value="UnitTitle">Unit Title</option>
                                <option value="Company">Company</option>
                                <option value="Strata">Strata</option>
                            </Form.Select>
                            {rural.FreeholdStatus === "" &&
                            <Form.Control as="label" className="required">Freehold Status is required</Form.Control>
                            }<br />
                        </Col>

                        <Form.Label column sm={2}>Property Category <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Select id="Category" value={rural.Category} onChange={(e) => handle(e)} required>
                                <option></option>
                                <option value="Lifestyle Blocks">Lifestyle Blocks</option>
                                <option value="Rural-Other">Rural - Other</option>
                                <option value="Horticulture">Horticulture</option>
                                <option value="Dairy">Dairy</option>
                                <option value="Dairy Support">Dairy Support</option>
                                <option value="Arable">Arable</option>
                                <option value="Forestry">Forestry</option>
                                <option value="Livestock">Livestock</option>
                                <option value="Livestock-Finishing">Livestock - Finishing</option>
                                <option value="Livestock-Grazing">Livestock - Grazing</option>
                                <option value="Livestock-Special">Livestock - Special</option>
                            </Form.Select>
                            {rural.Category === "" &&
                                <Form.Control as="label" className="required">Property Category is required</Form.Control>
                            }<br /></Col>
                    </Form.Group>

                    {/*Rural Information*/}
                    <Form.Label><h4>Rural Information</h4></Form.Label><br />
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Bareland</Form.Label>
                        <Col sm={2}>
                            <Form.Check type="checkbox" name="BareLand" checked={rural.BareLand} onChange={(e) => checkboxHandle(e)} />
                        </Col>
                        {rural.Category === "Lifestyle Blocks" && <>
                            <Form.Label column sm={2}>Farmlet</Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" name="Farmlet" checked={rural.Farmlet} onChange={(e) => checkboxHandle(e)} />
                            </Col>
                        </>
                        }
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Contour <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Select id="Contour" value={rural.Contour} onChange={(e) => handle(e)} required>
                                <option></option>
                                <option value="Flat">Flat</option>
                                <option value="Flat/Rolling">Flat / Rolling</option>
                                <option value="Rolling">Rolling</option>
                                <option value="Rolling/Steep">Rolling / Steep</option>
                                <option value="Steep/Rugged">Steep / Rugged</option>
                            </Form.Select>
                            <Form.Control as="label" className="required">Contour is required</Form.Control><br />
                        </Col>

                        <Form.Label column sm={2}>Irrigation Type <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Select id="IrrigationType" value={rural.IrrigationType} onChange={(e) => handle(e)} required>
                                <option></option>
                                <option value="Rotorainer">Rotorainer</option>
                                <option value="Centre Pivot">Centre Pivot</option>
                                <option value="K Line">K Line</option>
                                <option value="Border Dyke">Border Dyke</option>
                                <option value="Other">Other</option>
                                <option value="None">None</option>
                            </Form.Select>
                            <Form.Control as="label" className="required">Irrigation Type is required</Form.Control><br />
                        </Col>

                        <Form.Label column sm={2}>Water Supply <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Select id="WaterSupply" value={rural.WaterSupply} onChange={(e) => handle(e)} required>
                                <option></option>
                                <option value="Bores">Bores</option>
                                <option value="District Supply">District Supply</option>
                                <option value="Springs/Streams">Springs / Streams</option>
                                <option value="Dams">Dams</option>
                                <option value="Other">Other</option>
                                <option value="None">None</option>
                            </Form.Select>
                            <Form.Control as="label" className="required">Water Supply is required</Form.Control><br />
                        </Col>

                        <Form.Label column sm={2}>Land Area <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                                <Form.Control type="number" id="LandArea" value={rural.LandArea} onChange={(e) => handle(e)} required/>
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle><sub>m</sub>2</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                    <Dropdown.Item eventKey="1"><sub>m</sub>2</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">Ha</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup>
                            <Form.Control as="label" className="required">Land Area is required</Form.Control>
                            <br />
                        </Col>

                        <Form.Label column sm={2}>No. of Bedrooms <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Control type="number" id="Bedrooms" value={rural.Bedrooms} onChange={(e) => handle(e)} required/>
                            <Form.Control as="label" className="required">No.of Bedrooms is required</Form.Control>
                            <br />
                        </Col>

                        <Form.Label column sm={2}>No. of Bathrooms<sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Control type="number" id="Bathrooms" value={rural.Bathrooms} onChange={(e) => handle(e)} required/>
                            <Form.Control as="label" className="required">No.of Bathrooms is required</Form.Control>
                            <br />
                        </Col>

                        <Form.Label column sm={2}>Dwellings Total Count</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="number" id="Dwellings" value={rural.Dwellings} onChange={(e) => handle(e)} /><br />
                        </Col>

                        <Form.Label column sm={2}>Main Dwelling <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Control as="textarea" row="2" id="MainDwelling" value={rural.MainDwelling} onChange={(e) => handle(e)} required/>
                            <Form.Control as="label" className="required">Main Dwelling is required</Form.Control>
                            <br />
                        </Col>

                        <Form.Label column sm={2}>Additional Dwelling</Form.Label>
                        <Col sm={10}>
                            <Form.Control as="textarea" row="2" id="AdditionalDwelling" value={rural.AdditionalDwelling} onChange={(e) => handle(e)} /><br />
                        </Col>

                        <Form.Label column sm={2}>Improvements (Incl. Other Buildings) <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Control as="textarea" row="2" id="Improvements" value={rural.Improvements} onChange={(e) => handle(e)} />
                            <Form.Control as="label" className="required">Improvements (Incl. Other Buildings) is required</Form.Control>
                            <br />
                        </Col>

                        <Form.Label column sm={2}>Additional Comments</Form.Label>
                        <Col sm={10}>
                            <Form.Control as="textarea" row="2" id="AdditionalComments" value={rural.AdditionalComments} onChange={(e) => handle(e)} />
                            <Form.Control as="label" className="required">Additional Comments is required</Form.Control><br />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Supress Sale
                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip_supresssale}>
                                <sup>?</sup>
                            </OverlayTrigger></Form.Label>
                        <Col sm={1}>
                            <Form.Check type="checkbox" name="Suppressed" checked={rural.Suppressed} onChange={(e) => checkboxHandle(e)} />
                        </Col>
                    </Form.Group>

                    {/*Lifestyle Blocks fields*/}
                    {rural.Category === "Lifestyle Blocks" &&
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}><h5>Lifestyle Blocks fields</h5></Form.Label><br />
                            <Form.Label column sm={2}>GST Registered </Form.Label>
                            <Col sm={1}>
                                <Form.Check type="checkbox" name="GST" checked={rural.GST} onChange={(e) => checkboxHandle(e)} />
                            </Col>
                        </Form.Group>
                    }

                    {/*Horticulture fields*/}
                    {rural.Category === "Horticulture" &&
                        <>
                            <Form.Label><h5>Horticulture Fields</h5></Form.Label><br />
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>Horticulture Crop </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="text" id="HorticultureCrop" value={rural.HorticultureCrop} onChange={(e) => handle(e)}></Form.Control>
                                </Col>
                            </Form.Group>
                        </>
                    }<br />

                    {/*Dairy fields*/}
                    {rural.Category === "Dairy" &&
                        <>
                            <Form.Label><h5>Dairy fields</h5></Form.Label><br />
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>Dairy Company <sup className="aestric">*</sup></Form.Label>
                                <Col sm={10}>
                                    <Form.Select id="DairyCompany" value={rural.DairyCompany} onChange={(e) => handle(e)} required>
                                        <option></option>
                                        <option value="Fonterra">Fonterra</option>
                                        <option value="Open Country Dairy">Open Country Dairy</option>
                                        <option value="Tatua Co-op">Tatua Co-op</option>
                                        <option value="Miraka Ltd">Miraka Ltd</option>
                                        <option value="Westland Milk Products">Westland Milk Products</option>
                                        <option value="Synlait Canterbury">Synlait Canterbury</option>
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                    <Form.Control as="label" className="required">
                                        Dairy Company is required
                                    </Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Milk Solids</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="MilkSolids" value={rural.MilkSolids} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Cows Milked</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="CowsMilked" value={rural.CowsMilked} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                            </Form.Group>
                        </>
                    }<br />

                    {/*Arable fields*/}
                    {rural.Category === "Arable" &&
                        <>
                            <Form.Label><h5>Arable fields</h5></Form.Label><br />
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>Arable: Maize</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableMaize" value={rural.ArableMaize} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Oats</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableOats" value={rural.ArableOats} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Barley</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableBarley" value={rural.ArableBarley} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Brassica</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableBrassica" value={rural.ArableBrassica} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Lucerne</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableLucerne" value={rural.ArableLucerne} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Broadleaf</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableBroadleaf" value={rural.ArableBroadleaf} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Silage/Hay</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableHay" value={rural.ArableHay} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Animal Pasture</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableAnimalPasture" value={rural.ArableAnimalPasture} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Permanent Pasture</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArablePermanentPasture" value={rural.ArablePermanentPasture} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Kiwifruit</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableKiwifruit" value={rural.ArableKiwifruit} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Pipfruit</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArablePipfruit" value={rural.ArablePipfruit} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Vegetables</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableVegetables" value={rural.ArableVegetables} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Viticulture</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableViticulture" value={rural.ArableViticulture} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Orchard</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableOrchard" value={rural.ArableOrchard} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Other Grain</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableOtherGrain" value={rural.ArableOtherGrain} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Chicory</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableChicory" value={rural.ArableChicory} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Plantain</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArablePlantain" value={rural.ArablePlantain} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Ryegrass</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableRyegrass" value={rural.ArableRyegrass} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Clover</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableClover" value={rural.ArableClover} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Other Seed</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableOtherSeeds" value={rural.ArableOtherSeeds} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Fodder Crops</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableFodderCrops" value={rural.ArableFodderCrops} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Mixed</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableMixed" value={rural.ArableMixed} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Arable: Other</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ArableOther" value={rural.ArableOther} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                            </Form.Group>
                        </>
                    }
                    <br />

                    {/*Forestry fields*/}
                    {rural.Category === "Forestry" &&
                        <>
                            <Form.Label><h5>Forestry fields</h5></Form.Label><br />
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>Forestry Certificate</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="text" id="ForestryCertificate" value={rural.ForestryCertificate} onChange={(e) => handle(e)} ></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Forestry: Radiata </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ForestryRadiata" value={rural.ForestryRadiata} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Forestry: Douglas Fir</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ForestryDouglasFir" value={rural.ForestryDouglasFir} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Forestry: Eucalyptus</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ForestryEucalyptus" value={rural.ForestryEucalyptus} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Forestry: Acaia</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ForestryAcacia" value={rural.ForestryAcacia} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Forestry: Native</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="ForestryNative" value={rural.ForestryNative} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>Carbon Credits</Form.Label>
                                    <Col sm={2}>
                                        <Form.Check checked={rural.CarbonCredits} name="CarbonCredits" onChange={(e) => checkboxHandle(e)}></Form.Check>
                                    </Col>
                                    <Form.Label column sm={2}>Contract Management</Form.Label>
                                    <Col sm={2}>
                                        <Form.Check checked={rural.ContractManagement} name="ContractManagement" onChange={(e) => checkboxHandle(e)}></Form.Check><br /><br />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </>
                    }

                    {/*Livestock fields*/}
                    {rural.Category === "Livestock" &&
                        <>
                            <Form.Label><h5>Livestock fields</h5></Form.Label><br />
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>Livestock: Beef Heifers</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockBeefHeifers" value={rural.LivestockBeefHeifers} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Diary Heifers,1 Year</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDiaryHeifers1Year" value={rural.LivestockDiaryHeifers1Year} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Diary Heifers,2 Year</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDiaryHeifers2Year" value={rural.LivestockDiaryHeifers2Year} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Dairy Cows</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDairyCows" value={rural.LivestockDairyCows} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Stock Units </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockUnits" value={rural.LivestockUnits} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                            </Form.Group>
                        </>
                    }

                    {/*Livestock - Finishing fields*/}
                    {rural.Category === "Livestock-Finishing" &&
                        <>
                            <Form.Label><h5>Livestock - Finishing fields</h5></Form.Label><br />
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>Livestock:Beef Heifers</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockBeefHeifers" value={rural.LivestockBeefHeifers} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Diary Heifers,1 Year</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDiaryHeifers1Year" value={rural.LivestockDiaryHeifers1Year} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Diary Heifers,2 Year</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDiaryHeifers2Year" value={rural.LivestockDiaryHeifers2Year} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Dairy Cows</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDairyCows" value={rural.LivestockDairyCows} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Stock Units </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockUnits" value={rural.LivestockUnits} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                            </Form.Group>
                        </>
                    }

                    {/*Livestock - Grazing fields*/}
                    {rural.Category === "Livestock - Grazing" &&
                        <>
                            <Form.Label><h5>Livestock - Grazing fields</h5></Form.Label><br />
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>Livestock:Beef Heifers</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockBeefHeifers" value={rural.LivestockBeefHeifers} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Diary Heifers,1 Year</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDiaryHeifers1Year" value={rural.LivestockDiaryHeifers1Year} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Diary Heifers,2 Year</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDiaryHeifers2Year" value={rural.LivestockDiaryHeifers2Year} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Dairy Cows</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDairyCows" value={rural.LivestockDairyCows} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Stock Units </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockUnits" value={rural.LivestockUnits} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                            </Form.Group>
                        </>
                    }

                    {/*Livestock - Special fields*/}
                    {rural.Category === "Livestock-Special" &&
                        <>
                            <Form.Label><h5>Livestock - Special fields</h5></Form.Label><br />
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>Livestock:Beef Heifers</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockBeefHeifers" value={rural.LivestockBeefHeifers} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Diary Heifers,1 Year</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDiaryHeifers1Year" value={rural.LivestockDiaryHeifers1Year} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Diary Heifers,2 Year</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDiaryHeifers2Year" value={rural.LivestockDiaryHeifers2Year} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Livestock:Dairy Cows</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockDairyCows" value={rural.LivestockDairyCows} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                                <Form.Label column sm={2}>Stock Units </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="number" id="LivestockUnits" value={rural.LivestockUnits} onChange={(e) => handle(e)}></Form.Control><br />
                                </Col>
                            </Form.Group>
                        </>
                    }
                    {/*Additional Information*/}
                    <Form.Label><h4>Additional Information</h4></Form.Label><br />

                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Sale Method<sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <Form.Select id="SaleMethod" value={rural.SaleMethod} onChange={(e) => handle(e)} required>
                            <option></option>
                            <option>P - Private Treaty(Neg.)</option>
                            <option>A - Auction</option>
                            <option>T - Tender</option>
                            </Form.Select>
                            <Form.Control as="label" className="required">
                            Sale Method is required
                            </Form.Control><br />
                        </Col>


                        <Form.Label column sm={2}>List Price</Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                            <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                            <Form.Control type="number" id="ListPrice" value={rural.ListPrice} onChange={(e) => handle(e)} />
                            </InputGroup>
                            {rural.SaleMethod === 'P - Private Treaty(Neg.)' && rural.SaleMethod === "" &&
                            <Form.Control as="label" className="required">
                            List Price is required
                            </Form.Control>
                            }<br />
                        </Col>

                        <Form.Label column sm={2}>Sale Price <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                            <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                            <Form.Control type="number" value={rural.SalePrice} id="SalePrice" onChange={(e) => handle(e)} required/>
                            </InputGroup>
                            <Form.Control as="label" className="required">Sale Price is required</Form.Control><br />
                        </Col>

                        <Form.Label column sm={2}>List Date <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                            <Form.Control type="date" id="ListDate" value={rural.ListDate} onChange={(e) => handle(e)} required/>
                            </InputGroup>
                            <Form.Control as="label" className="required">ListDate is required</Form.Control><br />
                            {rural.ListDate > rural.AgreementDate &&
                            <Form.Control as="label" className="required">
                            List Date cannot be greater than Agreement Date
                            </Form.Control>
                            }
                            {rural.ListDate > rural.UnconditionalDate &&
                            <Form.Control as="label" className="required">
                            List Date cannot be greater than Unconditional Date
                            </Form.Control>
                            }<br />
                        </Col>

                        <Form.Label column sm={2}>Agreement Date <sup className="aestric">*</sup></Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                            <Form.Control type="date" id="AgreementDate" value={rural.AgreementDate} onChange={(e) => handle(e)} required/>
                            </InputGroup>
                            <Form.Control as="label" className="required">Agreement Date is required</Form.Control>
                            {rural.AgreementDate > rural.UnconditionalDate &&
                            <Form.Control as="label" className="required">
                            Agreement Date cannot be greater than Unconditional Date
                            </Form.Control>
                            }<br />
                        </Col>

                        <Form.Label column sm={2}>Unconditional Date
                            <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip_unconditionaldate}>
                            <sup>?</sup>
                            </OverlayTrigger></Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                            <Form.Control type="date" id="UnconditionalDate" value={rural.UnconditionalDate} onChange={(e) => handle(e)} required/>
                            </InputGroup>
                            <Form.Control as="label" className="required">Unconditional Date is required</Form.Control><br />
                        </Col>

                        <Form.Label column sm={2}>Settlement Date</Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                            <Form.Control type="date" id="SettlementDate" value={rural.SettlementDate} onChange={(e) => handle(e)} />
                            </InputGroup>
                            {rural.SettlementDate < rural.UnconditionalDate &&
                            <Form.Control as="label" className="required">
                            Settlement Date cannot be lesser than Unconditional Date
                            </Form.Control>
                            }<br />
                        </Col>

                        <Form.Label column sm={2}>Current Valuation</Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                                <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                                <Form.Control type="number" id="Valuation" value={rural.Valuation} onChange={(e) => handle(e)} />
                            </InputGroup><br />
                        </Col>

                        <Form.Label column sm={2}>Valuation Date</Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                                <Form.Control type="date" id="ValuationDate" value={rural.ValuationDate} onChange={(e) => handle(e)} />
                            </InputGroup><br />
                        </Col>

                        <Form.Label column sm={2}>Land Value</Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                                <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                                    <Form.Control type="number" id="LandValue" value={rural.LandValue} onChange={(e) => handle(e)} />
                            </InputGroup><br />
                        </Col>

                        <Form.Label column sm={2}>Improvement Value</Form.Label>
                        <Col sm={10}>
                            <InputGroup>
                                <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                                    <Form.Control type="number" id="ValuationImprovement" value={rural.ValuationImprovement} onChange={(e) => handle(e)} />
                            </InputGroup><br />
                        </Col>
                    </Form.Group>

                    {/*<Button variant="primary" type="submit" disabled>*/}
                    {/*    File Return*/}
                    {/*</Button>&nbsp;&nbsp;*/}
                    <Button style={{ backgroundColor: "#331b8c" }} onClick={Submit}>
                    File Return
                    </Button><br />
                    </Form>
                </>
            }
            </>
    );
}
const rural_test = {
    "OfficeReinzId": "10045569*0",
    "ReturnType": "Rural",
    "SaleIsConjoint": true,
    "ConjointOfficeReinzId": "Barfoot1, Barfoot2",
    "AdminNotes": "admin",
    "ReturnPeriod": "6/2024",
    "ExternalId": "FRONTEND_TEST",
    "AddressSearch": "9 Bellenden Crescent",
    "ValRef": "1234/12",
    "Unit": "9",
    "Suffix": "A",
    "StreetNumber": "9",
    "Street": "Bellenden Crescent",
    "Suburb": "Pokeno",
    "PostalCode": "2402",
    "CertificateOfTitle": "A",
    "FreeholdStatus": "Freehold",
    "Category": "Arable",
    "Comments": "",
    "DiseaseStatus": "",
    "LifestyleCategory": "",
    "LivestockCategory": "",
    "Outlook": "",
    "RuralCouncil": "",
    "BareLand": true,
    "Farmlet": false,
    "Contour": "Flat",
    "IrrigationType": "K Line",
    "WaterSupply": "Springs/Streams",
    "LandArea": "500",
    "Bedrooms": "4",
    "Bathrooms": "2",
    "Dwellings": "10",
    "MainDwelling": "Main Dwelling",
    "AdditionalDwelling": "Additional Dwelling",
    "Improvements": "Improvements (Incl. Other Buildings)",
    "AdditionalComments": "Additional Comments",
    "Suppressed": true,
    "GST": false,
    "HorticultureCrop": "",
    "DairyCompany": "",
    "MilkSolids": 0,
    "CowsMilked": 0,
    "ArableMaize": "1",
    "ArableOats": "2",
    "ArableBarley": "3",
    "ArableBrassica": "4",
    "ArableLucerne": "5",
    "ArableBroadleaf": "6",
    "ArableHay": "7",
    "ArableAnimalPasture": "8",
    "ArablePermanentPasture": "9",
    "ArableKiwifruit": "10",
    "ArablePipfruit": "11",
    "ArableVegetables": "12",
    "ArableViticulture": "13",
    "ArableOrchard": "14",
    "ArableOtherGrain": "15",
    "ArableChicory": "16",
    "ArablePlantain": "17",
    "ArableRyegrass": "18",
    "ArableClover": "19",
    "ArableOtherSeeds": "20",
    "ArableFodderCrops": "21",
    "ArableMixed": "22",
    "ArableOther": "23",
    "ForestryRadiata": 0,
    "ForestryDouglasFir": 0,
    "ForestryEucalyptus": 0,
    "ForestryAcacia": 0,
    "ForestryNative": 0,
    "CarbonCredits": false,
    "ContractManagement": false,
    "LivestockBeefHeifers": 0,
    "LivestockDiaryHeifers1Year": 0,
    "LivestockDiaryHeifers2Year": 0,
    "LivestockDairyCows": 0,
    "LivestockUnits": 0,
    "SaleMethod": "P - Private Treaty (Neg.)",
    "ListPrice": "1000000",
    "SalePrice": "950000",
    "ListDate": "2024-01-15",
    "AgreementDate": "2024-03-01",
    "UnconditionalDate": "2024-06-01",
    "SettlementDate": "2024-05-01",
    "Valuation": "1200000",
    "ValuationDate": "04/2024",
    "LandValue": "400000",
    "ValuationImprovement": "500000",
    "Latitude": 0,
    "Longitude": 0,
    "LinzTlaId": 0,
    "TlaId": 0,
    "TownCity": null,
    "LandClass": null
}