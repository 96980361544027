import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication,EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { loginRequest } from "./authConfig";



const msalInstance = new PublicClientApplication(msalConfig);
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getActiveAccount());
}

msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_START) {
        msalInstance.setActiveAccount(event.payload.account);
    }
    if (event.eventType === EventType.LOGIN_SUCCESS) {
        msalInstance.setActiveAccount(event.payload.account);
    }
    if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error) {
            console.log(event.error);
        }
    }
});

export const GetAccessToken = async (scopes) => {
    try {
        //const accessTokenRequest = {
        //    scopes
        //};
        const tokenResponse = await msalInstance.acquireTokenSilent(scopes);
        console.log(tokenResponse);
        return tokenResponse.accessToken;
    }
    catch (error) {
        console.error("getting error", error);
        throw error;
    }
    
};


root.render(
  <BrowserRouter basename={baseUrl}>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
  </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
