import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { msalConfig } from '../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate,} from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import { ProfileData } from "./ProfileData";

export const SignIn =()=> {
    const { instance } = useMsal();
    /*const myMSALObj = new PublicClientApplication(msalConfig);*/

    const handleLogin = (e) => {
         instance.loginRedirect(loginRequest);
       /* myMSALObj.loginRedirect(loginRequest);*/
        /*console.log("Hello world")*/
        console.log(e);
    };

    return (
        <div>
        <Button variant="light" onClick={handleLogin}>Sign In</Button>
        </div>

    );
}