import { LogLevel } from "@azure/msal-browser";
import { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";


/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */

 export const msalConfig = {
    auth: {
        clientId: "c02150a2-b9c4-4b31-986a-063105476b11",
        authority: "https://reinztestorg.b2clogin.com/reinztestorg.onmicrosoft.com/B2C_1A_Csi_signup_signin",
        knownAuthorities: ['reinztestorg.b2clogin.com'],
        redirectUri: "/"/*"https://localhost:44404/"*/,
        /*https://localhost:44404/*/
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};
export const loginRequest = {
    scopes: ['openid','https://reinztestorg.onmicrosoft.com/app_token/api_access'/*'https://reinztestorg.onmicrosoft.com/api_access/api_access'*/]
};
//export const GetAccessToken = async(scopes)  => {
//        const accessTokenRequest = {
//            scopes
//    };
//    const tokenResponse = await msalConfig.acquireTokenSilent(accessTokenRequest);
//    return tokenResponse.accessToken;
//   /* }, [])*/
//    //return (
//    //    <h1></h1>
//    //);
//};
