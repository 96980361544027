import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { DisplaySavedMessage } from './DisplaySavedMessage'
import { AddRuralReturn } from './AddRuralReturn';
import { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Placeholder from 'react-bootstrap/Placeholder';
import { GetAccessToken } from "../index";
import { useMsal } from "@azure/msal-react";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';


export function EditResidentialReturn({...props }) {
    const { instance } = useMsal();
    const [showRural, setShowRural] = useState(false);
    const [message, setMessage] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isVisible, setIsVisible] = useState(true);
    const [SelectedDateRange, setDateRangeValue] = useState([]);
    const [SelectedCategory, setReturnListCategory] = useState([]);
    const [SelectedReturnStatus, setReturnListStatus] = useState([]);
    const [SelectedReturnSaleMethod, setReturnListSaleMethod] = useState([]);
    const handleClose = () => setShowModal(false);
    const [residential, setresidential] = useState({
        SaleId: null,
        DbId:null,
        OfficeReinzId: null,
        ReturnType: "Residential",
        SaleIsConjoint: false,
        ConjointOfficeReinzId: null,
        AdminNotes: null,
        ReturnPeriod: (new Date((new Date()).setMonth((new Date()).getMonth()))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth()))).getFullYear(),
        ExternalId: null,
        AddressSearch: null,
        ValRef: null,
        Unit: null,
        Suffix: null,
        StreetNumber:null,
        Street: null,
        Suburb: null,
        PostalCode:0,
        Category: null,
        /*Title:"",*/
        FreeholdStatus: null,
        FloorArea: null,
        LandArea: null,
        Bedrooms: null,
        Bathrooms: null,
        ParkingTotal: null,
        IsNewDwelling: false,
        IsSaleAsIs: false,
        IsOwnerOccupied: false,
        IsFirstHomeBuyer: false,
        IsMortgageSale: false,
        IsInvestmentOrRental: false,
        Held: false,
        Suppressed: false,
        HasPool: false,
        IsGstExempt: false,
        DepositSaleOnly: false,
        SaleMethod: null,
        ListPrice: null,
        SalePrice: null,
        ListDate: null,
        AgreementDate: null,
        UnconditionalDate: null,
        SettlementDate: null,
        Valuation: null,
        ValuationDate:null,
        LandValue: null,
        ValuationImprovement: null,
        //fields used by API's but not by forms
        Latitude: null,
        Longitude: null,
        LinzTlaId: null,
        TlaId: null,
        CertificateOfTitle: null,
        TownCity: null,
        LandClass: null,
        Created:null
    });
    const newresidential = { ...residential }
    useEffect(() => {
        const fetchReturnsList = async () => {
            //console.log("profile");
            const url = "api/ReturnList/GetReturnLists";
            const tokenRequest = {
                scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
                account: instance.getActiveAccount()
            };
            const accessToken = await GetAccessToken(tokenRequest);

            try {
                await fetch(url,
                    {
                        method: "POST",
                        headers: new Headers(
                            {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${accessToken}`
                            }),
                        body: JSON.stringify({ fromDate: SelectedDateRange[0], toDate: SelectedDateRange[1], SelectedCategory: SelectedCategory, SelectedReturnStatus: SelectedReturnStatus, SelectedReturnSaleMethod: SelectedReturnSaleMethod })
                    })
                    .then((response) => {
                        console.log(response, 'response');
                        if (response.status !== 200 && response.status !== 201) {
                            console.log("not ok" + response.status);
                        }
                        else {
                            return response.json()
                        }
                    })
                    .then(data => {
                        console.log(data)
                        if (data !== null && data !== undefined) {
                            const newdata = data.find(x => x.saleId === props.saleId);
                            newresidential.SaleId = newdata.datamineSaleId;
                            newresidential.DbId = newdata.saleId;
                            newresidential.OfficeReinzId = newdata.organisationCode;
                            newresidential.ReturnType = newdata.saleType;
                            newresidential.ConjointOfficeReinzId = newdata.participantOfficeReinzIds;
                            newresidential.AddressSearch = newdata.fullAddress;
                            newresidential.ExternalId = newdata.externalId;
                            newresidential.ReturnPeriod = newdata.unconditionalDate !== null ? (newdata.unconditionalDate).slice(0, 10) : null;
                            newresidential.ValRef = newdata.valRef;
                            newresidential.Unit = newdata.unit;
                            newresidential.Suffix = newdata.suffix !== null ? newdata.suffix:""; 
                            newresidential.StreetNumber = newdata.streetNumber; 
                            newresidential.Street = newdata.street;
                            newresidential.Suburb = newdata.suburb;
                            newresidential.PostalCode = newdata.postalCode;
                            newresidential.Category = newdata.saleCategory;
                            newresidential.FreeholdStatus = newdata.freeholdStatus;

                            newresidential.FloorArea = newdata.floorArea;
                            newresidential.LandArea = newdata.landArea;
                            newresidential.Bedrooms = newdata.bedrooms;
                            newresidential.Bathrooms = newdata.bathrooms;
                            newresidential.ParkingTotal = newdata.parkingTotal;
                            newresidential.IsNewDwelling = newdata.newDwelling;
                            newresidential.IsSaleAsIs = newdata.saleAsIs;
                            newresidential.IsOwnerOccupied = newdata.ownerOccupied;
                            newresidential.IsFirstHomeBuyer= newdata.firstHomeBuyer;
                            newresidential.IsMortgageSale = newdata.mortgageSale;

                            newresidential.IsInvestmentOrRental = newdata.investmentOrRental;
                            newresidential.Held= newdata.held;
                            newresidential.Suppressed = newdata.suppressed;
                            newresidential.HasPool = newdata.hasPool;
                            newresidential.IsGstExempt = newdata.gstExempt;
                            newresidential.DepositSaleOnly = false;

                            newresidential.SaleMethod = newdata.saleMethod;
                            newresidential.ListPrice = newdata.listPrice;
                            newresidential.SalePrice = newdata.salePrice;
                            newresidential.ListDate = newdata.listDate !== null ? (newdata.listDate).slice(0, 10) : null;
                            newresidential.AgreementDate = newdata.agreementDate !== null ? (newdata.agreementDate).slice(0, 10) :null;
                            newresidential.UnconditionalDate = newdata.unconditionalDate !== null ? (newdata.unconditionalDate).slice(0, 10) : null;
                            newresidential.SettlementDate = newdata.settlementDate !== null ? (newdata.settlementDate).slice(0, 10) : null;
                            newresidential.Valuation = newdata.valuation;
                            newresidential.ValuationDate = newdata.valuationDate !== null ? (newdata.valuationDate).slice(0, 10) : null;
                            newresidential.LandValue = newdata.landValue;
                            newresidential.ValuationImprovement = newdata.valuationImprovement;
                            //fields used by API's but not by forms
                            newresidential.Latitude = newdata.latitude !== null ? newdata.latitude : 0;
                            newresidential.Longitude = newdata.longitude !== null ? newdata.longitude : 0;
                            newresidential.LinzTlaId = 0;
                            newresidential.TlaId = newdata.tlaId;
                            newresidential.CertificateOfTitle = newdata.certificateOfTitle;
                            newresidential.TownCity = newdata.townCity;
                            newresidential.LandClass = newdata.landClass;
                            newresidential.Created = newdata.created.slice(0, 10);
                            setresidential(newresidential);
                            
                        }
                        console.log(residential);
                    })
                    //setIsLoading(false)
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
            catch (error) {
                if (error.response) {
                    console.log(error);
                }
            }
        }
        fetchReturnsList();
    }, [])
    const isClicked = () => {
        setShowRural(() => !showRural);
        handleClose();
    }

    const [showLastMonths] = useState([
        {
            key: 1,
            value: (new Date((new Date()).setMonth((new Date()).getMonth()))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth()))).getFullYear()
        },
        {
            key: 2,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 1))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 1))).getFullYear()
        },
        {
            key: 3,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 2))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 2))).getFullYear()
        },
        {
            key: 4,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 3))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 3))).getFullYear()
        }
    ]);

    function handle(e) {
        const newresidential = { ...residential }
        newresidential[e.target.id] = e.target.value;
        setresidential(newresidential)
        console.log(residential)
    }
    function checkboxHandle(e) {
        const { name, value, checked, type } = e.target;
        setresidential
            (
                prevData =>
                ({
                    ...prevData,
                    [name]: type ===
                        'checkbox' ? checked : value
                }));
    }
    var addressEntered = "";
    function handleAddressSearch(e) {
        const { id } = e.target;
        addressEntered = e.target.value;
        setSelectedAddress(addressEntered);
        if (addressEntered.length >= 6) {
            handleAddress();
        }
    }
    const handleAddress = async () => {
        const url = "api/address/GetAddress";
        const tokenRequest = {
            //scopes: ["https://reinztestorg.onmicrosoft.com/api_access/api_access"],
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        const accessToken = await GetAccessToken(tokenRequest);
        try {
            fetch(url, {
                method: "POST",
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }),
                body: JSON.stringify(addressEntered)
            })
                .then((response) => {
                    console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                }).then(data => {
                    console.log(data)
                    if (data !== null && data !== undefined) {
                    setSearchResults(data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching addresses', error);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }

    }
    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    const Submit = async (e) => {
        e.preventDefault();
        //alert("Hello1");
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        scrollTop();
        const url = "api/ResidentialReturn/UpdateResidentialReturn/?id="+props.saleId;
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"/*"https://reinztestorg.onmicrosoft.com/api_access/api_access"*/],
            account: instance.getActiveAccount()
        };
        try {
            const accessToken = await GetAccessToken(tokenRequest);
            //fetch
            fetch(url,
                {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(residential),

                })
                .then((response) => {
                    console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                }).then(data => {
                    setMessage(data)
                    setTimeout(() => {
                        setMessage(false);
                    }, 5000);
                    //console.log(data)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }
    }
    const handleSelectAddress = (address) => {
        setIsVisible(false);
        newresidential.ValRef = address.valRef;
        newresidential.AddressSearch = address.addressSearch;
        newresidential.Unit = address.unit;
        newresidential.Suffix = address.suffix;
        newresidential.StreetNumber = address.streetNumber.toString();
        newresidential.Street = address.street;
        newresidential.Suburb = address.suburbName;
        newresidential.TownCity = address.townCity;
        newresidential.PostalCode = address.postcode;
        newresidential.LandValue = address.landValue;
        newresidential.Longitude = address.longitude;
        newresidential.Latitude = address.latitude;
        newresidential.Valuation = address.councilValuation;
        newresidential.Bathrooms = address.bathrooms;
        newresidential.Bedrooms = address.bedrooms;
        newresidential.FloorArea = address.floorArea;
        newresidential.LandArea = address.landArea;
        newresidential.ValuationImprovement = address.improvementValue;
        newresidential.CertificateOfTitle = address.certificateOfTitle;
        setresidential(newresidential);
    };


    const renderTooltip_returntype = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Changing this to a Rural Return will remove all residential specific fields
        </Tooltip>
    );

    const renderTooltip_saleisconjoint = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Marking a sale as conjoint will allow REINZ to match this sale with returns submitted by participating offices
        </Tooltip>
    );

    const renderTooltip_returnperiod = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Return Period will be determined by the date entered in the Unconditional Date field below
        </Tooltip>
    );

    const renderTooltip_externalid = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            If your agency requires your return to contain an ID for reporting purposes, enter it here
        </Tooltip>
    );

    const renderTooltip_addresssearch = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Selecting an address with this search box will overwrite any changes you've made to this form
        </Tooltip>
    );

    const renderTooltip_saleasis = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            In real estate, 'sale as is' denotes selling of a property without guarantees or warranties about its condition, often seen in cases of natural disasters or significant events.
        </Tooltip>
    );


    const renderTooltip_holdsale = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Holding a sale will prevent it being searchable in the unconditional sale month.
            Holding a sale will not enable real time sales searching for your agency.
        </Tooltip>
    );

    const renderTooltip_supresssale = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Supressing a sale prevents it being searchable until the sale has settled or 6 months.
            Supressing sales is actively monitored by REINZ and you will be asked the reason why it has supressed.
            Supressing should only be done for legal privacy reasons.
        </Tooltip>
    );

    const renderTooltip_unconditionaldate = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Unconditional Date will be used to determine which Return Period this return will be submitted under.
        </Tooltip>
    );
    return (
        <>
        <Modal size="lg" show={showModal} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to change this return from residential to a rural return?</Modal.Title>
                </Modal.Header>
                <Modal.Body>This will remove all residential data from the return form</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                Cancel
                </Button>
                <Button style={{ backgroundColor: "#331b8c" }} onClick={isClicked}>
                Confirm
                </Button>
                </Modal.Footer>
            </Modal>
           
            {showRural ? <AddRuralReturn /> :
                <>
                    <Placeholder xs={12} style={{ backgroundColor: "#331b8c" }} /><br/>
                    <Button style={{ backgroundColor: "#331b8c" }} onClick={props.onGoBack}>
                        <i className="bi bi-house"></i>&nbsp;Back to Home
                    </Button><br />
                    <Form noValidate validated={validated}><br />
                    {message === true && <DisplaySavedMessage />}

                        {/*Primary Property Information*/}
                        <Form.Label><h4>Primary Property Information</h4></Form.Label><br />
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Company<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text style={{ background: "transparent" }}>
                                    <i className="bi bi-search iconStyles"></i>
                                    </InputGroup.Text>
                                    <Form.Control type="text" placeholder="Start typing to search through Agencies" value={residential.OfficeReinzId} id="OfficeReinzId" onChange={(e) => handle(e)} required ></Form.Control>{/*onChange={(e) => handle(e) value={ data.name} */}
                                </InputGroup>
                                {residential.OfficeReinzId === null &&
                                    <Form.Control as="label" className="required">
                                    Company is required
                                    </Form.Control>}
                                <br />
                            </Col>

                            {/*Return Type*/}
                            <Form.Label column sm={2}>Return Type
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip_returntype}>
                                    <sup>?</sup>
                                </OverlayTrigger>
                            </Form.Label>

                            <Col sm={10}>
                                <Form.Label><span id="ReturnType" value={residential.ReturnType} onChange={(e) => handle(e)}>Residential</span></Form.Label>&nbsp;&nbsp;&nbsp;
                                <Button style={{ backgroundColor: "#331b8c" }} onClick={() => setShowModal(true)} ><i className="bi bi-arrow-repeat iconStyles"></i>&nbsp;Change Return Type</Button>
                                {showRural ? <AddRuralReturn /> : ""}
                            </Col>

                            <Form.Label column sm={2}>Sale Is Conjoint
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip_saleisconjoint}>
                                    <sup>?</sup>
                                </OverlayTrigger></Form.Label>
                            <Col sm={10}>
                                <Form.Check type="checkbox" checked={residential.SaleIsConjoint} name="SaleIsConjoint" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={2}>Conjoint Companies</Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text style={{ background: "transparent" }}>
                                        <i className="bi bi-search iconStyles"></i>
                                    </InputGroup.Text>
                                    <Form.Control type="text" id="ConjointOfficeReinzId" placeholder="Start typing to search through Agencies" value={residential.ConjointOfficeReinzId} onChange={(e) => handle(e)}/>
                                </InputGroup><br />
                            </Col>

                            <Form.Label column sm={2}>REINZ Admin Notes</Form.Label>
                            <Col sm={10}>
                                <Form.Control as="textarea" row="2" value={residential.AdminNotes} id="AdminNotes" onChange={(e) => handle(e)} /><br />
                            </Col>

                            <Form.Label column sm={2}>Return Period
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip_returnperiod}>
                                    <sup>?</sup>
                                </OverlayTrigger>
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Select type="select" id="ReturnPeriod" value={residential.ReturnPeriod} onChange={(e) => handle(e)}>
                                    {showLastMonths.map((info) => (
                                        <option key={info.key}>{info.value}</option>
                                    ))}
                                </Form.Select><br />
                                {/*<p>{residential.UnconditionalDate.toString().substring(0, 7) }</p>*/}
                                {/*{residential.UnconditionalDate.toString() === "" &&*/}
                                {/*<>*/}
                                {/*<span>Return Period requires Unconditional Date to be set</span>*/}
                                {/*<Form.Control as="label" className="required">Return Period is required</Form.Control><br />*/}
                                {/* </>*/}
                                {/*}          */}
                            </Col>


                            {/*External ID*/}
                            <Form.Label column sm={2}>External ID
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip_externalid}>
                                    <sup>?</sup>
                                </OverlayTrigger>
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="ExternalId" value={residential.ExternalId} onChange={(e) => handle(e)}></Form.Control><br />
                            </Col>

                            {/*Address Search*/}
                            <Form.Label column sm={2}>Address Search
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip_addresssearch}>
                                    <sup>?</sup>
                                </OverlayTrigger></Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text style={{ background: "transparent" }}>
                                    <i className="bi bi-search iconStyles"></i>
                                    </InputGroup.Text>
                                    <Form.Control type="text" autoComplete="street-address" placeholder="Start typing to search through addresses" id="AddressSearch" name="AddressSearch" value={newresidential.AddressSearch} onChange={(e) => handleAddressSearch(e)} minLength={8}></Form.Control>
                                </InputGroup>
                                {selectedAddress === null &&
                                    <p>Selecting an address with this search box will overwrite any changes you've made to this form</p>
                                }
                                {isVisible &&
                                    <ul id="addressSearch">
                                        {searchResults.map((address) => (
                                            <li key={address.id} onClick={() => handleSelectAddress(address)}>
                                                {address.addressSearch}
                                            </li>
                                        ))}
                                    </ul>
                                }
                                {/*Hide the dropdown on address search*/}
                                {/*{newresidential.AddressSearch !== null &&*/}
                                {/*    <Form.Select>*/}
                                {/*    <option>{newresidential.AddressSearch}</option>*/}
                                {/*    </Form.Select> */}
                                {/*}*/}
                                <br />
                            </Col>

                            {/*Val Ref*/}
                            <Form.Label column sm={2}>Val Ref</Form.Label>
                            <Col sm={10}>
                                {newresidential.ValRef !== null ?
                                    <Form.Control type="text" placeholder="Example  22900/42602" id="ValRef" value={newresidential.ValRef} onChange={(e) => handle(e)}></Form.Control>
                                    :
                                    <Form.Control type="text" placeholder="Example  22900/42602" id="ValRef" value={residential.ValRef} onChange={(e) => handle(e)}></Form.Control>
                                }
                                <br />
                            </Col>

                            {/* Unit Number*/}
                            <Form.Label column sm={2}>Unit Number</Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="Unit" value={residential.Unit} onChange={(e) => handle(e)}></Form.Control><br />
                            </Col>

                            {/*Suffix*/}
                            <Form.Label column sm={2}>Suffix</Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="Suffix" value={residential.Suffix} onChange={(e) => handle(e)} placeholder="Example A"></Form.Control><br />
                            </Col>

                            {/*Street Number*/}
                            <Form.Label column sm={2}>Street Number <sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="StreetNumber" value={residential.StreetNumber} onChange={(e) => handle(e)} required ></Form.Control>
                                {residential.StreetNumber === null &&
                                    <Form.Control as="label" className="required">Street Number is required</Form.Control>
                                }<br />
                            </Col>

                            {/*Street Name*/}
                            <Form.Label column sm={2}>Street Name <sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="Street" value={residential.Street} onChange={(e) => handle(e)} required ></Form.Control>
                                {residential.Street === null &&
                                    <Form.Control as="label" className="required" value="street_name">Street Name is required</Form.Control>
                                }<br />
                            </Col>

                            {/*Suburb*/}
                            <Form.Label column sm={2}>Suburb <sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" placeholder="Start typing to search through suburbs" id="Suburb" value={residential.Suburb} onChange={(e) => handle(e)} required></Form.Control>
                                {residential.Suburb === null &&
                                    <Form.Control as="label" className="required">Suburb is required</Form.Control>
                                }<br />
                            </Col>

                            {/*Post Code*/}
                            <Form.Label column sm={2}>Post Code</Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="PostalCode" value={residential.PostalCode} onChange={(e) => handle(e)}></Form.Control><br />
                            </Col>

                            {/*Property Category*/}
                            <Form.Label column sm={2}>Property Category <sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Select id="Category" value={newresidential.Category} onChange={(e) => handle(e)} required>
                                    <option></option>
                                    <option value="Residence">Residence</option>
                                    <option value="Residential Section">Residential Section</option>
                                    <option value="Unit">Unit</option>
                                    <option value="Apartment">Apartment</option>
                                    <option value="Townhouse">Townhouse</option>
                                    <option value="Home and Income">Home and Income</option>
                                    <option value="Residential Investment Block">Residential Investment Block</option>
                                    <option value="Residential- Other">Residential- Other</option>
                                </Form.Select>
                                {residential.Category === null &&
                                    <Form.Control as="label" className="required">Property Category is required</Form.Control>
                                }<br />
                            </Col>

                            {/*Title*/}
                            <Form.Label column sm={2}>Title</Form.Label>
                            <Col sm={10}>
                                {newresidential.CertificateOfTitle !== null ?
                                    <Form.Control type="text" id="CertificateOfTitle" value={newresidential.CertificateOfTitle} onChange={(e) => handle(e)} ></Form.Control>
                                    :
                                    <Form.Control type="text" id="CertificateOfTitle" value={residential.CertificateOfTitle} onChange={(e) => handle(e)} ></Form.Control>
                                }
                                <br />
                            </Col>
                            {/*Latitude*/}
                            <div style={{ display: "none" }}>
                                <Form.Label column sm={2}>Latitude</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="text" id="Latitude" value={residential.Latitude} onChange={(e) => handle(e)}></Form.Control>
                                    <br />
                                </Col>
                            </div>

                            {/*Longitude*/}
                            <div style={{ display: "none" }}>
                                <Form.Label column sm={2}>Longitude</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="text" id="Longitude" value={residential.Longitude} onChange={(e) => handle(e)}></Form.Control>
                                    <br />
                                </Col>
                            </div>

                            {/*Freehold Status*/}
                            <Form.Label column sm={2}>Freehold Status <sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Select id="FreeholdStatus" value={residential.FreeholdStatus} onChange={(e) => handle(e)} required>
                                    <option></option>
                                    <option>Unknown</option>
                                    <option>Freehold</option>
                                    <option>Leasehold</option>
                                    <option>Crosslease</option>
                                    <option>Unit Title</option>
                                    <option>Company</option>
                                    <option>Strata</option>
                                </Form.Select>
                                {residential.FreeholdStatus === null &&
                                    <Form.Control as="label" className="required">Freehold Status is required</Form.Control>
                                }<br />
                            </Col>
                        </Form.Group>

                        {/*Residential Information*/}
                        <Form.Label><h4>Residential Information</h4></Form.Label><br />

                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Floor Area</Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    {newresidential.FloorArea !== null ?
                                        <Form.Control type="number" id="FloorArea" value={newresidential.FloorArea} onChange={(e) => handle(e)} />
                                        :
                                        <Form.Control type="number" id="FloorArea" value={residential.FloorArea} onChange={(e) => handle(e)} />
                                    }
                                    <Dropdown as={ButtonGroup} id=" FloorArea" value={residential.FloorArea}>
                                        <Dropdown.Toggle><sub>m</sub>2</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="1"><sub>m</sub>2</Dropdown.Item>
                                            <Dropdown.Item eventKey="2">Ha</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </InputGroup>
                                {residential.Category === 'Residence' && residential.FloorArea === null &&
                                    <Form.Control as="label" className="required">Floor Area is required</Form.Control>
                                }
                                {residential.Category === 'Unit' && residential.FloorArea === null &&
                                    <Form.Control as="label" className="required">Floor Area is required</Form.Control>
                                }
                                {residential.Category === 'Townhouse' && residential.FloorArea === null &&
                                    <Form.Control as="label" className="required">Floor Area is required</Form.Control>
                                }
                                {residential.Category === 'Home and Income' && residential.FloorArea === null &&
                                    <Form.Control as="label" className="required">Floor Area is required</Form.Control>
                                }
                                {residential.Category === 'Apartment' && residential.FloorArea === null &&
                                    <Form.Control as="label" className="required">Floor Area is required</Form.Control>
                                }<br />

                            </Col>

                            <Form.Label column sm={2}>Land Area</Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    {newresidential.LandArea !== null ?
                                        <Form.Control type="number" id="LandArea" value={newresidential.LandArea} onChange={(e) => handle(e)} />
                                        :
                                        <Form.Control type="number" id="LandArea" value={residential.LandArea} onChange={(e) => handle(e)} />
                                    }

                                    <Dropdown as={ButtonGroup} >
                                        <Dropdown.Toggle id="LandArea" value={residential.LandArea} onChange={(e) => handle(e)}><sub>m</sub>2</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                        <Dropdown.Item eventKey="1"><sub>m</sub>2</Dropdown.Item>
                                        <Dropdown.Item eventKey="2">Ha</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </InputGroup>
                                {residential.Category === 'Residential Section' &&
                                    <Form.Control as="label" className="required">Land Area is required</Form.Control>
                                }
                                {residential.Category === 'Residence' &&
                                    <Form.Control as="label" className="required">Land Area is required</Form.Control>
                                }
                                {residential.Category === 'Unit' &&
                                    <Form.Control as="label" className="required">Land Area is required</Form.Control>
                                }
                                {residential.Category === 'Townhouse' &&
                                    <Form.Control as="label" className="required">Land Area is required</Form.Control>
                                }
                                {residential.Category === 'Home and Income' &&
                                    <Form.Control as="label" className="required">Land Area is required</Form.Control>
                                }<br />
                            </Col>

                            <Form.Label column sm={2}>No. of Bedrooms</Form.Label>
                            <Col sm={10}>
                                {residential.Bedrooms !== null &&
                                    <Form.Control type="number" id="Bedrooms" value={residential.Bedrooms} onChange={(e) => handle(e)} />
                                }
                                {residential.Category === 'Apartment' &&
                                    <Form.Control as="label" className="required">No. of Bedrooms is required</Form.Control>
                                }<br />
                            </Col>

                            <Form.Label column sm={2}>No. of Bathrooms</Form.Label>
                            <Col sm={10}>
                                {residential.Bathrooms !== null &&
                                    <Form.Control type="number" id="Bathrooms" value={residential.Bathrooms} onChange={(e) => handle(e)} />
                                }

                                {residential.Category === 'Residence' &&
                                    <Form.Control as="label" className="required">No. of Bathrooms is required</Form.Control>
                                }
                                {residential.Category === 'Unit' &&
                                    <Form.Control as="label" className="required">No. of Bathrooms is required</Form.Control>
                                }
                                {residential.Category === 'Townhouse' &&
                                    <Form.Control as="label" className="required">No. of Bathrooms is required</Form.Control>
                                }
                                {residential.Category === 'Home and Income' &&
                                    <Form.Control as="label" className="required">No. of Bathrooms is required</Form.Control>
                                }
                                {residential.Category === 'Apartment' &&
                                    <Form.Control as="label" className="required">No. of Bathrooms is required</Form.Control>
                                }<br />
                            </Col>

                            <Form.Label column sm={2}>Total Parking</Form.Label>
                            <Col sm={10}>
                                <Form.Control type="number" id="ParkingTotal" value={residential.ParkingTotal} onChange={(e) => handle(e)} />
                                {residential.Category === 'Residence' &&
                                    <Form.Control as="label" className="required">Total Parking is required</Form.Control>
                                }
                                {residential.Category === 'Unit' &&
                                    <Form.Control as="label" className="required">Total Parking is required</Form.Control>
                                }
                                {residential.Category === 'Townhouse' &&
                                    <Form.Control as="label" className="required">Total Parking is required</Form.Control>
                                }
                                {residential.Category === 'Home and Income' &&
                                    <Form.Control as="label" className="required">Total Parking is required</Form.Control>
                                }
                                {residential.Category === 'Apartment' &&
                                    <Form.Control as="label" className="required">Total Parking is required</Form.Control>
                                }<br />
                            </Col>

                            <Form.Label column sm={1}>New Dwelling</Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.IsNewDwelling} name="IsNewDwelling" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={1}>Sale As Is
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip_saleasis}>
                                    <sup>?</sup>
                                </OverlayTrigger></Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.IsSaleAsIs} name="IsSaleAsIs" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={1}>Owner is Occupier </Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.IsOwnerOccupied} name="IsOwnerOccupied" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={1}>First Home Buyer</Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.IsFirstHomeBuyer} name="IsFirstHomeBuyer" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={1}>Mortgage Sale </Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.IsMortgageSale} name="IsMortgageSale" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={1}>Investment or Rental </Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.IsInvestmentOrRental} name="IsInvestmentOrRental" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={1}>Hold Sale
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip_holdsale}>
                                    <sup>?</sup>
                                </OverlayTrigger></Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.Held} name="Held" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={1}>Supress Sale
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip_supresssale}>
                                    <sup>?</sup>
                                </OverlayTrigger></Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.Suppressed} name="Suppressed" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={1}>Deposit Sale Only</Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.DepositSaleOnly} name="DepositSaleOnly" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={1}>Has Pool</Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.HasPool} name="HasPool" onChange={(e) => checkboxHandle(e)} />
                            </Col>

                            <Form.Label column sm={1}>GST Exempt</Form.Label>
                            <Col sm={2}>
                                <Form.Check type="checkbox" checked={residential.IsGstExempt} name="IsGstExempt" onChange={(e) => checkboxHandle(e)} />
                            </Col>
                        </Form.Group>

                        {/*Additional Information*/}
                        <Form.Label><h4>Additional Information</h4></Form.Label><br />

                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Sale Method <sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Select id="SaleMethod" value={residential.SaleMethod} onChange={(e) => handle(e)} required>
                                    <option></option>
                                    <option>P - Private Treaty(Neg.)</option>
                                    <option>A - Auction</option>
                                    <option>T - Tender</option>
                                </Form.Select>
                                <Form.Control as="label" className="required">
                                    Sale Method is required
                                </Form.Control><br />
                            </Col>


                            <Form.Label column sm={2}>List Price<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                                    <Form.Control type="number" id="ListPrice" value={residential.ListPrice} onChange={(e) => handle(e)} required/>
                                </InputGroup>
                                {residential.SaleMethod === 'P - Private Treaty(Neg.)' && residential.SaleMethod === "" &&
                                    <Form.Control as="label" className="required">
                                        List Price is required
                                    </Form.Control>
                                }<br />

                            </Col>

                            <Form.Label column sm={2}>Sale Price<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                                    <Form.Control type="number" id="SalePrice" value={residential.SalePrice} onChange={(e) => handle(e)} required/>
                                </InputGroup>
                                <Form.Control as="label" className="required">SalePrice is required</Form.Control><br />
                            </Col>

                            <Form.Label column sm={2}>List Date<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                <Form.Control type="text" placeholder="Enter a date or click the icon to open the datepicker" id="ListDate" value={residential.ListDate} onChange={(e) => handle(e)} required/>
                                </InputGroup>
                                <Form.Control as="label" className="required">ListDate is required</Form.Control><br />
                                {residential.ListDate > residential.AgreementDate &&
                                    <Form.Control as="label" className="required">
                                        List Date cannot be greater than Agreement Date
                                    </Form.Control>
                                }
                                {residential.ListDate > residential.UnconditionalDate &&
                                    <Form.Control as="label" className="required">
                                        List Date cannot be greater than Unconditional Date
                                    </Form.Control>
                                }<br />
                            </Col>

                            <Form.Label column sm={2}>Agreement Date<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <Form.Control type="text" id="AgreementDate" value={residential.AgreementDate} onChange={(e) => handle(e)} required/>
                                </InputGroup>
                                <Form.Control as="label" className="required">Agreement Date is required</Form.Control>
                                {residential.AgreementDate > residential.UnconditionalDate &&
                                    <Form.Control as="label" className="required">
                                        Agreement Date cannot be greater than Unconditional Date
                                    </Form.Control>
                                }<br />
                            </Col>

                            <Form.Label column sm={2}>Unconditional Date
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip_unconditionaldate}>
                                    <sup>?</sup>
                                </OverlayTrigger></Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <Form.Control type="text" id="UnconditionalDate" value={residential.UnconditionalDate} onChange={(e) => handle(e)} required/>
                                </InputGroup>
                                <Form.Control as="label" className="required">Unconditional Date is required</Form.Control><br />
                            </Col>

                            <Form.Label column sm={2}>Settlement Date</Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <Form.Control type="text" id="SettlementDate" value={residential.SettlementDate} onChange={(e) => handle(e)} format={"yyyy-MM-dd"} />
                            </InputGroup>
                            {residential.SettlementDate < residential.UnconditionalDate &&
                            <Form.Control as="label" className="required">
                            Settlement Date cannot be lesser than Unconditional Date
                            </Form.Control>
                            }<br />
                            </Col>

                            <Form.Label column sm={2}>Current Valuation</Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                                    <Form.Control type="number" id="Valuation" value={residential.Valuation} onChange={(e) => handle(e)} />
                                </InputGroup><br />
                            </Col>

                            <Form.Label column sm={2}>Valuation Date</Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <Form.Control type="text" id="ValuationDate" value={residential.ValuationDate} onChange={(e) => handle(e)} />
                                </InputGroup><br />
                            </Col>

                            <Form.Label column sm={2}>Land Value</Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                                    {newresidential.LandValue !== null ?
                                        <Form.Control type="number" id="LandValue" value={newresidential.LandValue} onChange={(e) => handle(e)} /> :
                                        <Form.Control type="number" id="LandValue" value={residential.LandValue} onChange={(e) => handle(e)} />
                                    }
                                </InputGroup><br />
                            </Col>

                            <Form.Label column sm={2}>Improvement Value</Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                                        <Form.Control type="number" id="ValuationImprovement" value={residential.ValuationImprovement} onChange={(e) => handle(e)} />
                                    
                                </InputGroup><br />
                            </Col>
                        </Form.Group>
                        {/*<Button variant="primary" type="submit" disabled>*/}
                        {/*    Cancel*/}
                        {/*</Button>&nbsp;&nbsp;*/}
                        <Button style={{ backgroundColor: "#331b8c" }} onClick={Submit}>
                            Update Return
                        </Button><br />
                    </Form>
                </>
            }

        </>

    );

}

