import React from "react";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from 'react';
import { NavTabs } from './NavTabs';
import { GetAccessToken } from "../index";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

/**
 * Renders information about the user obtained from MS Graph 
 * @param props
 */

export function ProfileData() {
    const { instance, accounts } = useMsal();
    const [data, setData] = useState(false);
    const [Loading, setLoading] = useState(false);
    /*let accessTokenResponse = GetAccessToken().accessTokenResponse;*/

    useEffect(() => {
        fetchProfileData();
    }, []);

        const fetchProfileData = async () => {
            setLoading(true);
            const url = "api/returnportalpermissions/GetData";
            const tokenRequest = {
                scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
                account: instance.getActiveAccount()
            };
            const accessToken = await GetAccessToken(tokenRequest);

                    try {
                        await fetch(url, {
                        method: "GET",
                        headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                        }),
                    })
                        .then(response => response.json())
                        .then(data =>
                        {
                            setData(data);
                            setLoading(false);
                        })
                            //setIsLoading(false)
                        .catch(error => {
                            console.error('Error:', error);
                        });
                    }
                    catch (error) {
                        if (error.response) {
                            console.log(error);
                        }
                    }
        }
        
    
    return (
        <div style={{ minHeight: "100vh"}}>
            {Loading && 
                <div style={{textAlign:"center"} }>
                    <Button style={{ backgroundColor:"#331b8c", width:"500px" }} disabled>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                    <h3>Loading...</h3>
                </Button>
            </div>}
            <div>
            {data &&
                <NavTabs /> 
                }
            </div>
        </div >     
      
    );
}
/*<h2 style={{ color: 'red', textAlign: "center" }}>There is an error while loading forms </h2>*/